import React, { createContext, useContext, useState, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { products, getProductById, calculatePrice } from '../data/products';
import { dmarcProducts, calculateDmarcPrice } from '../data/dmarc-products';

const Context = createContext();

export const StateContext = ({ children }) => {
  const [showCart, setShowCart] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQuantities, setTotalQuantities] = useState(0);
  const [qty, setQty] = useState(1);

  let foundProduct;
  let index;

  const onAdd = (product, quantity, options) => {
    const price = calculatePrice(product, options).ourPrice;
    const newItem = {
      ...product,
      quantity,
      price,
      options,
      digiCertType: product.digiCertType,
      digiCertEndpoint: product.digiCertEndpoint,
    };

    setTotalPrice((prevTotalPrice) => prevTotalPrice + price * quantity);
    setTotalQuantities((prevTotalQuantities) => prevTotalQuantities + quantity);

    const existingItemIndex = cartItems.findIndex((item) => item.id === newItem.id);

    if (existingItemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex].quantity += quantity;
      setCartItems(updatedCartItems);
    } else {
      setCartItems((prevItems) => [...prevItems, newItem]);
    }

    toast.success(`${quantity} ${product.name} added to the cart.`);

    // Log the updated cart items for debugging
    console.log('Updated cart items:', [...cartItems, newItem]);
  };

  const onAddDmarc = (product, quantity, selectedOption) => {
    if (!product) {
      console.error('Invalid product:', product);
      return;
    }

    const priceInfo = calculateDmarcPrice(product, selectedOption);
    const newItem = {
      ...product,
      quantity,
      price: priceInfo.annualPrice,
      selectedOption,
      type: 'dmarc',
      cartDisplay: {
        primaryLabel: 'Emails',
        secondaryLabel: 'Domains'
      }
    };

    setTotalPrice((prevTotalPrice) => prevTotalPrice + priceInfo.annualPrice * quantity);
    setTotalQuantities((prevTotalQuantities) => prevTotalQuantities + quantity);

    const existingItemIndex = cartItems.findIndex((item) => item.id === product.id);

    if (existingItemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex] = {
        ...updatedCartItems[existingItemIndex],
        quantity: updatedCartItems[existingItemIndex].quantity + quantity,
        selectedOption // Update selected option in case it changed
      };
      setCartItems(updatedCartItems);
    } else {
      setCartItems(prev => [...prev, newItem]);
    }

    toast.success(`${quantity} ${product.name} added to the cart.`);
  };

  const onRemove = (product) => {
    const itemToRemove = cartItems.find((item) => item.id === product.id);
    if (!itemToRemove) return;

    const updatedCartItems = cartItems.filter((item) => item.id !== product.id);
    setCartItems(updatedCartItems);

    setTotalPrice((prevTotalPrice) => prevTotalPrice - itemToRemove.price * itemToRemove.quantity);
    setTotalQuantities((prevTotalQuantities) => prevTotalQuantities - itemToRemove.quantity);
  };

  const toggleCartItemQuantity = (id, value) => {
    foundProduct = cartItems.find((item) => item._id === id);
    index = cartItems.findIndex((product) => product._id === id);
    const newCartItems = cartItems.filter((item) => item._id !== id);

    if (value === 'inc') {
      setCartItems([...newCartItems, { ...foundProduct, quantity: foundProduct.quantity + 1 }]);
      setTotalPrice((prevTotalPrice) => prevTotalPrice + foundProduct.price);
      setTotalQuantities((prevTotalQuantities) => prevTotalQuantities + 1);
    } else if (value === 'dec') {
      if (foundProduct.quantity > 1) {
        setCartItems([...newCartItems, { ...foundProduct, quantity: foundProduct.quantity - 1 }]);
        setTotalPrice((prevTotalPrice) => prevTotalPrice - foundProduct.price);
        setTotalQuantities((prevTotalQuantities) => prevTotalQuantities - 1);
      }
    }
  };

  const incQty = () => {
    setQty((prevQty) => prevQty + 1);
  };

  const decQty = () => {
    setQty((prevQty) => {
      if (prevQty - 1 < 1) return 1;

      return prevQty - 1;
    });
  };

  const addToCart = (product, quantity, options) => {
    const totalPrice = calculatePrice(product, options).ourPrice;
    setCartItems([
      ...cartItems,
      {
        ...product,
        quantity,
        options,
        totalPrice,
      },
    ]);
  };

  return (
    <Context.Provider
      value={{
        showCart,
        setShowCart,
        cartItems,
        totalPrice,
        totalQuantities,
        qty,
        incQty,
        decQty,
        calculatePrice,
        onAdd,
        onAddDmarc,
        toggleCartItemQuantity,
        onRemove,
        setCartItems,
        setTotalPrice,
        setTotalQuantities,
        addToCart,
        products,
        getProductById,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useStateContext = () => useContext(Context);
