import Image from 'next/image';
import Link from 'next/link';

const FooterSmall = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-small-wrapper">
      <div className="container-default w-container">
        <div className="footer-small">
          <Link href="/" className="footer-logo-wrapper small mg-bottom-0 w-inline-block">
            <Image
              src="/images/logo-light.svg"
              alt="Logo - Brandymail.com"
              className="footer-logo"
              height={50}
              width={150}
            />
          </Link>
          <p className="color-neutral-800 mg-bottom-0">
            Copyright &copy; {currentYear} Valiweb Services, LLC. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterSmall;
