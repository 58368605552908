'use client';
import { usePathname } from 'next/navigation';
import HeaderWrapper from './Common/HeaderWrapper';
import Footer from './Common/Footer';
import FooterSmall from './Common/FooterSmall';
import FooterSimpleDark from './Common/FooterSimpleDark';
import { Analytics } from '@vercel/analytics/react';

export default function RootLayout({ children }) {
  const pathname = usePathname();

  const pathsWithoutHeader = [
    '/auth/signup',
    '/auth/signin',
    '/auth/verify',
    '/auth/check-email',
    '/forgot-password',
    '/auth/reset-password',
    '/privacy-policy',
    '/terms',
  ];

  const pathsWithoutHeaderFooter = [
    '/landing/digicert-verified-mark-certificate',
    '/landing/digicert-common-mark-certificate',
    '/landing/digicert-government-mark-certificate',
  ];

  const isHeaderHidden =
    pathsWithoutHeader.includes(pathname) || pathsWithoutHeaderFooter.includes(pathname);
  const isFooterHidden = pathsWithoutHeaderFooter.includes(pathname);
  const isAccountPage = pathname?.startsWith('/account/');

  return (
    <>
      {!isHeaderHidden && <HeaderWrapper />}
      <main>{children}</main>
      <Analytics />
      {!isFooterHidden && !isHeaderHidden ? (
        isAccountPage ? (
          <FooterSmall />
        ) : (
          <Footer />
        )
      ) : isFooterHidden ? (
        <FooterSimpleDark />
      ) : (
        <FooterSmall />
      )}
    </>
  );
}
