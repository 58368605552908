export const dmarcProducts = [
  {
    id: 'dmarc-plus',
    name: 'Plus',
    title: 'DMARC Plus',
    abbreviation: 'Plus',
    type: 'dmarc',
    shortDescription: 'Self-service DMARC & BIMI configuration',
    image: '/images/BM-Icon-DMARC-Plus.png',
    description:
      'Easily configure DMARC & BIMI in a self-service manner to quickly benefit from VMCs.',
    bullets: ['2 Domains', '3 Months Data History', '1 Invited User'],
    options: [
      {
        emails: '100,000',
        price: 35.99,
        msrp: 39.99,
        billingPeriod: 'monthly',
        annualPrice: 431.88,
      },
      {
        emails: 'Unlimited',
        price: 191.99,
        msrp: 199.0,
        billingPeriod: 'monthly',
        annualPrice: 2303.88,
      },
    ],
  },
  {
    id: 'dmarc-premium',
    name: 'Premium',
    title: 'DMARC Premium',
    abbreviation: 'Premium',
    type: 'dmarc',
    shortDescription: 'Advanced DMARC enforcement with team collaboration',
    image: '/images/BM-Icon-DMARC-Premium.png',
    description:
      'Enforce DMARC policies with colleagues in a user-friendly interface & leverage BIMI protocols to display verified logos.',
    bullets: ['4 Domains', '1 Year Data History', 'Unlimited Invited Users'],
    options: [
      {
        emails: '100,000',
        price: 71.99,
        msrp: 79.99,
        billingPeriod: 'monthly',
        annualPrice: 863.88,
      },
      {
        emails: 'Unlimited',
        price: 299.99,
        msrp: 319.99,
        billingPeriod: 'monthly',
        annualPrice: 3599.88,
      },
    ],
  },
];

export const calculateDmarcPrice = (product, selectedOption) => {
  // Handle old structure
  if (product.options) {
    const option = product.options.find((opt) => opt.emails === selectedOption);
    if (!option) {
      // If no option found, return the first option as default
      const defaultOption = product.options[0];
      return {
        msrp: defaultOption.msrp,
        msrpFormatted: `$${defaultOption.msrp.toFixed(2)}`,
        price: defaultOption.price,
        annualPrice: defaultOption.annualPrice,
        total: defaultOption.annualPrice,
      };
    }
    return {
      msrp: option.msrp,
      msrpFormatted: `$${option.msrp.toFixed(2)}`,
      price: option.price,
      annualPrice: option.annualPrice,
      total: option.annualPrice,
    };
  }

  // Handle new structure
  const emailFeature = product.features.find((f) => f.name === 'Emails');
  const emailOption = emailFeature.options.find((o) => o.value === selectedOption);
  if (!emailOption) {
    // If no option found, return the first option as default
    const defaultOption = emailFeature.options[0];
    return {
      msrp: defaultOption.msrp,
      msrpFormatted: `$${defaultOption.msrp.toFixed(2)}`,
      price: defaultOption.price,
      annualPrice: defaultOption.annualPrice,
      total: defaultOption.annualPrice,
    };
  }

  return {
    msrp: emailOption.msrp,
    msrpFormatted: `$${emailOption.msrp.toFixed(2)}`,
    price: emailOption.price,
    annualPrice: emailOption.annualPrice,
    total: emailOption.annualPrice,
  };
};
